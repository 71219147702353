import styled from 'styled-components';

export const PageColumn = styled.div`
  /* min-width: 1000px;
  max-width: 1400px; */
  width: 90%;
  display: flex;
  flex-direction: column;
  margin: auto;
`;
